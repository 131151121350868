<template>
  <div class="section_wrap type-bbs pt-50 pb-80">
    <!-- 카르텔 리스트 -->
    <div class="ml-20 mr-20 w-100">
      <div class="under-line pb-10 flex-row title">
        <h3 class="mr-10">{{ program.title }}</h3>
        <h6>마피아 서비스의 공지사항을 확인하세요.</h6>
      </div>
    </div>

    <div
        v-if="items_notify.length <= 0"
        class="w-100 flex-column justify-center mt-100"
    >
      <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
      <div class="mt-20">내역이 없습니다.</div>
    </div>

    <ul class="mt-30 pl-30" style="flex-basis: 100%;" v-else>
      <li
          v-for="(item, index) in items_notify"
          :key="'item_' + index"
          style="display: flex; justify-content: space-around"
          class="mr-20 mb-25"
      >
        <div class="picture">

          <img
              v-if="item.sending_member_profle_nft_card_img_url"
              :src="$request.upload_url(item.sending_member_profle_nft_card_img_url)"
              referrerpolicy="no-referrer"
          >
          <img
              v-else
              :src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
              referrerpolicy="no-referrer"
          />
        </div>
        <div class="text_area ml-20" style="flex : 1">
          <strong>{{ item.note_type_name }} {{ item.note_title }}</strong>
          <p>
            {{ item.note_contents }}
          </p>
          <p class="mt-10 flex justify-space-between">
            <span>{{ item.sending_member_nickname }}</span>
            <span>{{ item.sendiingdate ? item.sendiingdate.split('T')[0] : ''  }}</span>
          </p>
        </div>


      </li>
    </ul>
    <div class="flex justify_content_center mt-30 w-100">
<!--      <div class="pagination">
        <button href="#">&laquo;</button>
        <button href="#">1</button>
        <button href="#" class="active">2</button>
        <button href="#">3</button>
        <button href="#">4</button>
        <button href="#">5</button>
        <button href="#">&raquo;</button>
      </div>-->

      <div class="pagination">
        <button @click="page_unit--" :disabled="canDecrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-left
          </v-icon>
        </button>
        <button v-for="page in pages" :key="page" :class="{'active' : currentPage === page }" @click="currentPage = page">{{ page }}</button>
        <button @click="page_unit++" :disabled="canIncrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-right
          </v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mafia118-1'
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: '알림'
        , title: '알림'
        , type: 'mypage'
      }
      , items_notify: []
      , item_search: {
        page: 1
        , list_cnt: 10
      }

      , currentPage: 1
      , page_unit : 1
      , page_tot_count : 1
    }
  }

  , computed: {
    pages() {

      let endPage = this.page_unit * 5
      let startPage = endPage - 4

      if (this.page_tot_count < endPage) {
        endPage = (endPage - (endPage - this.page_tot_count))
      }

      let pages = []
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }
      return pages
    }
    , canDecrement() {
      if (this.page_unit <= 1) {
        return true
      }
      return false
    }

    , canIncrement() {
      if (this.page_tot_count < (this.page_unit * 5)) {
        return true
      }
      return false
    }

  }

  ,  watch: {
    currentPage() {
      this.$nextTick(() => {
        // 페이지 변경시에는 데이터를 업데이트 합니다.
        // 이곳에서 API를 호출하여 새로운 데이터를 가져올 수 있습니다.
        console.log("Page changed: ", this.currentPage);
        this.getData(this.currentPage)
      });
    },
  }


  , methods: {
    getData: async function (page_num = 1) {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_notify_list
          , data: {
            member_number: this.user.member_number
            , page_number: page_num
            , pagerecnum: this.item_search.list_cnt
          }
          , type: true
        })

        if (result.success) {
          this.items_notify = result.data.member_note_list
          this.page_tot_count = result.data.page_info.page_tot_count
        } else {
          throw result.message
        }
      } catch (e) {
        console.log('mafia118 getData ', e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , to: function (item) {
      let r = item.relay_info
      if (r) {
        r = JSON.parse(r)
        item.idx = r.cartl_number
        item.code = r.board_type_code
        item.b_id = r.board_number
        item.bbs_id = r.bulletin_number
      }

      switch (item.note_type_code.replace('\r', '')) {
        case 'AL00100001':
          this.$emit('to', {name: 'notice'})
          break
        case 'AL00100002':
          this.$emit('to', {
            name: 'mafia058',
            params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}
          })
          break
        case 'AL00100003':
          this.$emit('to', {name: 'staking'})
          break
        case 'AL00100004':
          this.$emit('to', {name: 'mafia049', params: {idx: item.idx}})
          break
        case 'AL00100005':
          this.$emit('to', {name: 'mafia049', params: {idx: item.idx}})
          break
        case 'AL00100006':
          this.$emit('to', {
            name: 'mafia058',
            params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}
          })
          break
        case 'AL00100007':
          this.$emit('to', {
            name: 'mafia058',
            params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}
          })
          break
        case 'AL00100008':
          this.$emit('to', {
            name: 'mafia058',
            params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}
          })
          break
        case 'AL00100009':
          this.$emit('to', {name: 'mafia049', params: {idx: item.idx}})
          break
      }
    }
  }
  , created() {
    this.$emit('onLoad', this.program)
    this.getData()
  }
}
</script>
<style>
.off {
  opacity: 1
}

.section_wrap {
  display: flex;
  flex-wrap: wrap;
}

.type-bbs h6 {
  line-height: 260%;
}

.type-bbs li.under-line {
  padding-bottom: 10px;
}

.type-bbs .title {
  border-bottom: 1px solid #b2b2b2;
  box-sizing: content-box
}

.text-gray {
  color: var(--light-Gray03);
}

.text-red {
  color: var(--red);
}

.text-black {
  color: var(--black);
}

.type-bbs .picture img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
}





.type-bbs .pagination {
  display: inline-block;
}

.type-bbs .pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 4px;
}

.type-bbs .pagination button.active {
  background-color: var(--blue02);
  color: white;
  border: 2px solid var(--blue02);
}

.type-bbs .pagination button:hover:not(.active) {background-color: #ddd;}
</style>